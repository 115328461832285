



































































import Vue from "vue";
import Component from "vue-class-component";
import GroupLineChart from "@/components/group/GroupLineChart.vue";
import taskModule from "@/store/modules/tasks";

@Component({
  components: {
    GroupLineChart,
  },
})
export default class GroupStats extends Vue {
  get isLoaded(): boolean {
    return taskModule.status == "loaded";
  }

  get medianScore(): number {
    return taskModule.medianWeekScore;
  }

  get minScore(): number {
    return taskModule.minMaxWeekScore[0];
  }

  get currentScore(): number {
    return taskModule.currentWeekScore ?? 0;
  }

  get maxScore(): number {
    return taskModule.minMaxWeekScore[1];
  }
}
